<script lang="ts" setup>
import {ref} from 'vue';
import {UserPolicyType} from 'src/constants/enums';
import UserPolicySignOff from 'src/pages/Activation/components/UserPolicySignOff.vue';

const policyType = ref(UserPolicyType.UserPolicyPrivacyPolicy);
</script>

<template>
  <q-page padding>
    <UserPolicySignOff :checkBoxLabelLocalizerKey="'activate_privacy_policy_read'"
                       :headingLocalizerKey="'activate_privacy_policy'"
                       :modalBodyLocalizerKey="'activate_privacy_policy_checkbox_text'"
                       :nextRoute="'Activate.EULA'"
                       :policyType="policyType"
                       :progress="39"
                       :scrollToRead="true"/>
  </q-page>
</template>