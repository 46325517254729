<script lang="ts" setup>
import {
  useDashboardStore,
  useGeneralPurposeModalStore
} from 'src/store';
import {
  computed,
  defineProps,
  type PropType,
  ref,
} from 'vue';
import moment from 'momentCultured';
import { i18n } from '@/plugins/i18n';
import { type Task } from 'src/types/webContracts';
import { TaskType, PIQTaskIdEnum } from 'src/constants/enums';
import { createInformationModal } from 'src/utils/generalPurposeModalFactory';
import { PIQSetupPages } from 'src/services/piqSetupService';
import { onDeactivated } from 'vue';
import { useRoute } from 'vue-router';

const dashboardStore = useDashboardStore(),
  generalPurposeModalStore = useGeneralPurposeModalStore();

const props = defineProps({
  class: {
    type: String
  },
  label: {
    type: String
  },
  tasks: {
    type: Array as PropType<Task[] | undefined>
  },
  taskType: {
    type: Number as PropType<TaskType | undefined>
  }
});

const isOpen = ref(false);

onDeactivated(() => {
  if (useRoute().name === 'Interrupts') 
  {
    isOpen.value = false;
  }
});

// Computed properties
const remainingTaskCount = computed(() => {
  let result = 0;
  if (props.tasks) {
    result = props.tasks.filter((x: Task) => !x.isComplete).length;
  }
  return result;
});

const noTasksDueMessage = computed(() => {
  let result;
  if (props.tasks && props.tasks.length == 0) {
    switch (props.taskType) {
      case TaskType.education:
        result = dashboardStore.nextEducationDate
          ? i18n.global.t('vue3_home_next_education_task', [dashboardStore.nextEducationDate])
          : i18n.global.t('home_no_more_education');
        break;
      case TaskType.survey:
        result = dashboardStore.nextSurveyDate
          ? i18n.global.t('vue3_home_next_survey_task', [dashboardStore.nextSurveyDate])
          : i18n.global.t('home_no_more_surveys');
        break;
      case TaskType.routine:
        result = dashboardStore.nextRoutineDate
          ? i18n.global.t('vue3_home_next_exercise_task', [dashboardStore.nextRoutineDate])
          : i18n.global.t('home_pause_exercises');
        break;
      default:
        result = '';
        break;
    }
  }
  return result;
});

function toggleMe() {
  isOpen.value = !isOpen.value;
}

function showDisabledMessageModal(task: Task): void {
  switch (props.taskType) {
    case TaskType.routine:
      if (!isFirstAvailableRoutine(task)) {
        const title = i18n.global.t('routine_break').toString();
        const template = i18n.global.t('routine_break_message').toString().replace(/\\n/g, '<br/>');
        const modal = createInformationModal(title, template);
        generalPurposeModalStore.setModal(modal);
      }
      break;
    default:
      break;
  }
}

function isFirstEdItemTask(task: Task): boolean {
  let result = false;

  if (props.tasks && props.tasks.length > 0) {
    // Data
    const edItems = props.tasks.filter((item: Task) => item.taskType === TaskType.education);

    // Conditions
    const isEducationItem = task.taskType === TaskType.education;
    const isFirstEdItem = task.id === edItems[0]?.id;

    // Evaluation
    result = isFirstEdItem && isEducationItem;
  }
  return result;
}

function taskDisabled(task: Task): boolean {

  if (task.isComplete) {
    //Base Station Setup instruction remain accessible after completed,
    if (task.taskType == TaskType.piq && task.id == PIQTaskIdEnum.BaseStationSetup) return false;
    return true;

  } else {
    if (task.taskType === TaskType.education && !isFirstEdItemTask(task)) return true
    return false;
  }
}

function isLocked(task: Task) {
  let result = false;

  // Conditions for each type
  switch (props.taskType) {
    case TaskType.routine:
      result = !isFirstAvailableRoutine(task);
      break;
    case TaskType.piq:
      const isPIQBaseSetupTask = task.id === PIQTaskIdEnum.BaseStationSetup;
      result = isPIQBaseSetupTask && !areOtherTasksCompleted(task)
      break;
  }

  return result;
}

function isFirstAvailableRoutine(task: Task): boolean {
  let result = false;
  const isRoutineTask = props.taskType == TaskType.routine;
  const tasks = props.tasks;
  if (isRoutineTask && tasks) {
    // Data
    const now = moment();
    const minimuMinuteLength = 60;
    const released = moment().add(minimuMinuteLength - dashboardStore.mostRecentCompletedTime, 'minute');
    const taskItems = tasks.filter((item: Task) => item.taskType === props.taskType);

    // Conditions
    const isFirstItem = task.id === taskItems[0]?.id;
    const tickSignatureMatchesFirstItem = task.tickSignature === taskItems[0]?.tickSignature;
    const hasBeenReleased = now.isSameOrAfter(released);
    const meetsMinimumMinuteLength = dashboardStore.mostRecentCompletedTime >= minimuMinuteLength;
    const isTaskAvailable = meetsMinimumMinuteLength && hasBeenReleased;

    // Evaluation
    result = isFirstItem && tickSignatureMatchesFirstItem && isTaskAvailable;
  }
  return result;
}

function areOtherTasksCompleted(task: Task): boolean {
  let result = false;
  if (props.tasks) {
    // Data
    const incompleteTasks = props.tasks.filter((item: Task) => item.taskType === task.taskType
      && !item.isComplete
      && item.id !== task.id);

    // Conditions
    const hasIncompleteTasks = incompleteTasks.length === 0;

    // Evaluation
    result = hasIncompleteTasks;
  }
  return result;
}

function generateTaskLink(task: Task): object | undefined {
  let result: object | undefined = undefined;
  switch (props.taskType) {
    case TaskType.piq:

      if (task.id == PIQTaskIdEnum.BaseStationSetup && !isLocked(task)) {
        result = {
          name: 'PIQSetup.BaseStationSetup',
          params: {
            itemId: task.id
          }
        };
      } else if (task.id === PIQTaskIdEnum.RosaProfileSetup && !task.isComplete) {
        if (!task.nextStep
          || task.nextStep.length === 0
          || task.nextStep === PIQSetupPages.TermAndConditions
          || task.nextStep === PIQSetupPages.Terms) {
          task.nextStep = PIQSetupPages.Start;
        }

        const routeName = `PIQSetup.${task.nextStep}`;
        result = {
          name: routeName,
          params: {
            itemId: task.id
          }
        };
      } else if (task.id === PIQTaskIdEnum.BaseStationPrecheck && !task.isComplete) {
        result = {
          name: 'PIQSetup.BaseStationPrecheck',
          params: {
            itemId: task.id
          }
        };
      }
      break;

    case TaskType.education:
      if (!task.isComplete && isFirstEdItemTask(task)) {
        result = {
          name: 'Education.Education',
          params: {
            itemId: task.id,
            tickSignature: task.tickSignature,
          },
        };
      }
      break;

    case TaskType.survey:
      if (!task.isComplete) {
        result = {
          name: 'Survey',
          params: {
            surveyId: task.id,
            tickSignature: task.tickSignature,
          },
        };
      }
      break;

    case TaskType.routine:
      if (!task.isComplete && isFirstAvailableRoutine(task)) {
        result = {
          name: 'Routine.Routine',
          params: {
            itemId: task.id,
            tickSignature: task.tickSignature,
          },
        };
      }
      break;

    default:
      break;
  }

  return result;
}

function taskStatusIcon(task: Task): string {
  if (task.isComplete) {
    return 'fal fa-check-circle';
  } else if (isLocked(task)) {
    return 'far fa-lock';
  } else {
    return 'fal fa-circle';
  }
}
</script>

<template>
  <q-expansion-item v-model="isOpen" :duration="0" hide-expand-icon header-class="q-expansion-item__toggle">

    <!-- Toggle -->
    <template #header>

      <!-- Icon -->
      <q-item-section class="q-item__section--icon" side>
        <slot name="icon" />
      </q-item-section>

      <!-- Label (main) -->
      <q-item-section>{{ props.label }}</q-item-section>

      <!-- Expand Icon -->
      <q-item-section class="q-item__section--expand" side>
        <q-icon color="dark-teal" :name="'fas ' + (isOpen ? 'fa-chevron-up' : 'fa-chevron-down')" size="24px" />
      </q-item-section>

      <!-- Todo Count -->
      <q-item-section class="q-item__section--todo" side>
        <div :class="{ 'bg-content-assets-badge-inactive' : remainingTaskCount === 0 }">{{ remainingTaskCount }}</div>
      </q-item-section>
    </template>

    <!-- Tasks -->
    <q-list v-if="props.tasks && props.tasks.length > 0">

      <!-- Task -->
      <q-item v-for="task in tasks" @click="showDisabledMessageModal(task)" :disable="taskDisabled(task)"
        :key="task.tickSignature" :to="generateTaskLink(task)" :clickable="true">

        <!-- Status Icon -->
        <q-item-section class="flex-center q-item__section--status" side>
          <q-icon color="dark-teal" :name="taskStatusIcon(task)" size="28px" />
        </q-item-section>

        <!-- Label (main) -->
        <q-item-section>{{ task.name }}</q-item-section>

        <!-- Arrow Icon -->
        <q-item-section class="q-item__section--arrow" side>
          <q-icon color="accent" name="far fa-long-arrow-right" size="28px" />
        </q-item-section>
      </q-item>
    </q-list>

    <!-- No Tasks -->
    <p v-else>{{ noTasksDueMessage }}</p>
  </q-expansion-item>
</template>

<style lang="scss" scoped>
.q-expansion-item {
  $q-expansion-item-brdr: 1px solid $todays-tasks-brdr-clr;
  $q-item-section-opacity: 0.6;
  $q-task-item-status-w: 28px;

  background-color: white;

  &:not(:last-child) {
    margin-block-end: 18px;
  }

  :deep(.q-item) {
    color: $dark-teal;
    padding: 0;

    &:hover,
    &:focus {
      color: $dark-teal;
      background-color: initial;
    }

    // Section
    .q-item__section {
      padding: 0;
    }

    // Disabled
    &.disabled,
    &:disabled {
      pointer-events: none;
      opacity: 1 !important;
    }
  }

  // Toggle (header)
  :deep(.q-expansion-item__toggle) { // .q-item
    min-height: 70px;
    border: $q-expansion-item-brdr;
    border-radius: $generic-border-radius;

    // Section
    .q-item__section {

      &:not(.q-item__section--icon) {
        padding-inline: 20px;
      }

      // Icon
      &--icon { // .q-item__section.q-item__section--side
        $q-item-icon-w: 78px;

        position: relative;
        width: $q-item-icon-w;
        color: $white;
        align-items: center;
        margin-block: -1px;
        margin-inline-start: -1px;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: $q-item-icon-w;
          height: 100%;
          content: '';
          border-block-start: 70px solid $dark-teal;
          border-inline-end: 13px solid transparent;
          border-radius: $generic-border-radius 0 0 $generic-border-radius;
        }

        svg {
          z-index: 1;
          width: 21px;
          height: auto;
        }
      }

      // Label
      &--main { // .q-item__section
        font-weight: map-get($text-weights, bold);
        font-size: map-get($font-sizes, '22');
        line-height: $line-height-sm;
      }

      // Todo Count
      &--todo { // .q-item__section

        div {
          $q-item-todo-size: 24px;

          width: $q-item-todo-size;
          height: $q-item-todo-size;
          background-color: $accent;
          line-height: $q-item-todo-size;
          color: $white;
          text-align: center;
          border-radius: 50%;
        }
      }
    }

    &:disabled,
    &.disabled {

      // Toggle
      :deep(.q-item) {

        // Section
        .q-item__section {

          // Expand
          &--expand { // .q-item__section
            display: none;
          }

          // Todo Count
          &--todo { // .q-item__section
            opacity: $q-item-section-opacity;

            div {
              background-color: $dark-teal;
            }
          }
        }
      }
    }

    @media (max-width: 479px) {
      flex-wrap: wrap;

      // Section
      .q-item__section {
        padding: 0;

        &:not(.q-item__section--icon) {
          height: 40px;
          padding-inline: 10px;
        }

        // Icon
        &--icon { // .q-item__section.q-item__section--side
          width: calc(100% + 2px);
          max-width: none;
          height: 50px;
          background-color: $dark-teal;
          border-radius: $generic-border-radius $generic-border-radius 0 0;
          margin-block-end: 0;
          margin-inline-end: -1px;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  // Expanded
  &.q-expansion-item--expanded { // .q-expansion-item

    // Toggle
    :deep(.q-expansion-item__toggle) { // .q-item
      border-radius: $generic-border-radius $generic-border-radius 0 0;

      // Section
      .q-item__section {

        // Icon
        &--icon::before { // .q-item__section.q-item__section--side
          border-radius: $generic-border-radius 0 0 0;
        }
      }
    }
  }

  // Content
  :deep(.q-expansion-item__content) { // .q-expansion-item
    padding: 3px 10px 7px;
    border: $q-expansion-item-brdr;
    border-block-start-style: none;
    border-radius: 0 0 $generic-border-radius $generic-border-radius;

    // Task
    .q-item {
      padding-block: 27px;
      border-block-end: 1px solid $border-clr;

      &:last-child {
        border-block-end: none;
      }

      // Section
      .q-item__section {
        $q-item-section-pad-x: 20px;

        padding-inline: $q-item-section-pad-x;

        &:last-child {
          padding-inline-end: 0;
        }

        // Status Icon
        &--status { // .q-item__section
          width: $q-task-item-status-w + $q-item-section-pad-x;
          min-height: 30px;
          padding-inline-start: 0 !important;
        }

        // Label
        &--main { // .q-item__section
          font-size: map-get($font-sizes, '20');
          word-wrap: break-word;
        }

        @media (max-width: 599px) {
          $q-item-section-pad-x: 10px;

          padding-inline: $q-item-section-pad-x;

          // Status Icon
          &--status { // .q-item__section
            width: $q-task-item-status-w + $q-item-section-pad-x;
          }
        }
      }

      // Disabled
      &.disabled,
      &:disabled {

        // Section
        .q-item__section {

          // Status Icon & Label
          &--status,
          &--main { // .q-item__section
            opacity: $q-item-section-opacity;
          }

          // Arrow Icon
          &--arrow { // .q-item__section
            display: none;
          }
        }
      }
    }
  }
}
</style>