<script lang="ts" setup>
import {useDashboardStore} from 'src/store';
import { useLocalizationStore } from '@/store/localizationModule';
import {
  computed,
  defineProps,
  ref,
  watchEffect
} from 'vue';

const dashboardStore = useDashboardStore();
const localizationStore = useLocalizationStore();

const props = defineProps({
  unreadMsgCt: {
    type: Number
  }
});

const syncValue = ref(props.unreadMsgCt);

const isLoading = computed(() => !dashboardStore.patient),
    isPIQ = computed(() => dashboardStore.patient?.hasPersonaIQ),
    isPIQandDisabled = computed(() => dashboardStore.patientConfig?.isDischarged);

const myStatsRouteName = computed(() => {
    return (localizationStore.locale === 'en-US' && isPIQ.value)
        ? 'Dashboard.MyStats.PersonaIQ'
        : 'Dashboard.MyStats'
});

const emit = defineEmits(['update:unreadMsgCt']);

watchEffect(syncValue, (newValue) => {
  emit('update:unreadMsgCt', newValue);
});
</script>

<template>
  <q-list>
    <zbm-skeleton-loader :height="isPIQandDisabled ? '120px' : '220px'"
                         :loading="isLoading"
                         width="100%">
      <!-- Home -->
      <q-item clickable
              :to="{ name: 'Dashboard.Home' }">
        
        <!-- Active -->
        <q-item-section class="q-item__section--dot"
                        side>
          <div />
        </q-item-section>
  
        <!-- Icon -->
        <q-item-section class="q-item__section--icon"
                        side>
          <q-icon name="fas fa-home" />
        </q-item-section>
  
        <!-- Label -->
        <q-item-section>{{ $t('pwe_home') }}</q-item-section>
      </q-item>
  
      <!-- My Stats -->
      <q-item clickable
              :to="{ name: myStatsRouteName }">
  
        <!-- Active -->
        <q-item-section class="q-item__section--dot"
                        side>
          <div />
        </q-item-section>
        
        <!-- Icon -->
        <q-item-section class="q-item__section--icon"
                        side>
          <q-icon name="fas fa-chart-bar" />
        </q-item-section>
  
        <!-- Label -->
        <q-item-section>{{ $t('stats') }}</q-item-section>
      </q-item>
  
      <!-- Education -->
      <q-item v-if="!isPIQandDisabled"
              clickable
              :to="{ name: 'Dashboard.Education' }">
  
        <!-- Active -->
        <q-item-section class="q-item__section--dot"
                        side>
          <div />
        </q-item-section>
  
        <!-- Icon -->
        <q-item-section class="q-item__section--icon"
                        side>
          <q-icon name="fas fa-graduation-cap" />
        </q-item-section>
  
        <!-- Label -->
        <q-item-section>{{ $t('gen_education') }}</q-item-section>
      </q-item>
  
      <!-- Messages -->
      <q-item v-if="!isPIQandDisabled"
              clickable
              :to="{ name: 'Dashboard.Messages' }">
  
        <!-- Active -->
        <q-item-section class="q-item__section--dot"
                        side>
          <div />
        </q-item-section>
  
        <!-- Icon -->
        <q-item-section class="q-item__section--icon"
                        side>
          <q-icon name="fas fa-comments" />
        </q-item-section>
  
        <!-- Label -->
        <q-item-section>{{ $t('pwe_messages') }}</q-item-section>
        
        <!-- Unread Message Count -->
        <q-item-section v-if="unreadMsgCt"
                        class="q-item__section--count"
                        side>
          <div>{{ unreadMsgCt }}</div>
        </q-item-section>
      </q-item>
    </zbm-skeleton-loader>
  </q-list>
</template>

<style lang="scss" scoped>
.q-item {
  align-items: center;
  padding: 8px 0;
  
  &:not(:last-child) {
    margin-block-end: 20px;
  }

  &:active,
  &.active,
  &--active,
  &.q-router-link--active {
    background-color: initial;
    color: map-get($co-brand-clrs, content-trg-secondary-def);
  }

  // Active
  .q-item__section--dot { // .q-item__section--side
    $q-item-dot-size: 6px;
    $q-item-dot-pad: 12px;
    
    width: $q-item-dot-size + $q-item-dot-pad;
    padding-right: 0;
    padding-inline-end: $q-item-dot-pad;
    
    > div {
      display: none;
      width: $q-item-dot-size;
      height: $q-item-dot-size;
      background-color: $dash-nav-active-clr;
      border-radius: 50%;
    }
  }
  &.q-router-link--active .q-item__section--dot > div {
    display: block;
  }
  
  // Icon
  .q-item__section--icon { // .q-item__section--side
    $q-item-icon-pad: 14px;
    
    width: 30px + $q-item-icon-pad;
    font-size: 24px;
    color: currentColor;
    align-items: center;
    padding-right: 0;
    padding-inline-end: $q-item-icon-pad;
  }
  
  // Label
  :deep(.q-item__section--main) {
    font-size: map-get($font-sizes, '18');
    line-height: map-get($line-heights, '24');
  }
  
  // Unread Message Count
  .q-item__section--count { // .q-item__section--side
    $q-item-count-size: 24px;
    $q-item-count-pad: 14px;
    
    width: $q-item-count-size + $q-item-count-pad;
    padding-inline-start: $q-item-count-pad;
    
    > div {
      width: $q-item-count-size;
      height: $q-item-count-size;
      background-color: $accent;
      font-size: map-get($font-sizes, '14');
      line-height: $q-item-count-size;
      color: $white;
      text-align: center;
      border-radius: 50%;
    }
  }
}
</style>